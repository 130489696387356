interface ITimer {
  started_at: string | null;
  finished_at: string | null;
  type: string | null;
  meta_id: number | null;
  isReview: boolean;
}

interface StateInterface {
  timer: ITimer;
}

const resetTimer = (): ITimer => {
  return {
    started_at: null,
    finished_at: null,
    meta_id: null,
    type: null,
    isReview: false,
  };
};
/**
 * States
 */
export const state = (): StateInterface => ({
  timer: resetTimer(),
});

export const mutations = {
  StartTimer (state: StateInterface, timer: ITimer) {
    state.timer.started_at = (this as any)
      .$moment()
      .format('YYYY-MM-DD hh:mm:ss');
    state.timer.meta_id = timer.meta_id ? timer.meta_id : null;
    state.timer.type = timer.type;
    state.timer.isReview = timer.isReview;
    state.timer.finished_at = null;
  },

  EndTimer (state: StateInterface) {
    state.timer.finished_at = (this as any)
      .$moment()
      .format('YYYY-MM-DD hh:mm:ss');
  },

  ClearTimer (state: StateInterface) {
    state.timer = resetTimer();
  },
};
