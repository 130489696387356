
import { Component, PropSync } from 'vue-property-decorator';
import ValidationRules from '@mixins/validationRules';
import { Action, State } from '~/node_modules/nuxt-class-component';
import { ParamProfileBasicInfo } from '~/@types/myAccount';
import { User } from '~/@types/auth';

@Component
export default class accountEditAccount extends ValidationRules {
  @PropSync('isOpen') public isDialogOpen!: boolean;
  @State('user', { namespace: 'auth' }) public user!: User;
  @Action('UPDATE_PROFILE_BASIC_INFO', { namespace: 'myAccount' })
  public UPDATE_PROFILE_BASIC_INFO!: any;

  @Action('GET_USER_UPDATES', { namespace: 'myAccount' })
  public GET_USER_UPDATES!: any;

  // Data
  public isLoading: boolean = false;
  public isFormValid: boolean = false;
  public isEditingProfile: boolean = false;
  public authUser: User | null = null;

  public newPasswordError: boolean = false;
  public confirmationPasswordError: boolean = false;
  public currentPasswordError: boolean = false;

  public form: any = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  };

  // Iitilize all the data
  public mounted (): void {
    if ((this as any).user.id) {
      (this as any).authUser = (this as any).user;
      (this as any).GET_USER_UPDATES().then((res: any) => {
        if ((this as any).authUser) {
          (this as any).form.first_name = (this as any).authUser.first_name;
          (this as any).form.last_name = (this as any).authUser.last_name;
          (this as any).form.email = (this as any).authUser.email;
        }
      });
    } else {
      (this as any).authUser = null;
    }
  }

  /**
   * Create a new user profile
   *
   * @private
   */
  public editProfile (): void {
    (this as any).isEditingProfile = !(this as any).isEditingProfile;
  }

  /**
   * Validate if user want to change password
   *
   * @private
   */

  /**
   * Create a new user profile
   *
   * @private
   */
  public updateAccount (): void {
    if ((this as any).$refs.formAddClient) {
      // Validate the form
      ((this as any).$refs.formAddClient as any).validate();
      (this as any).$nextTick(() => {
        // If the validation pass
        if ((this as any).isFormValid) {
          (this as any).isLoading = true;
          // Setup request payload
          const data: ParamProfileBasicInfo = {
            firstName: (this as any).form.first_name,
            lastName: (this as any).form.last_name,
          };

          (this as any).UPDATE_PROFILE_BASIC_INFO(data).then((res: any) => {
            if (res.code === 200) {
              (this as any).$alert.show('Updated');
              (this as any).isDialogOpen = false;
            }
          });
          (this as any).isLoading = false;
        }
      });
    }
  }
}
