/**
 * Local Storage
 * =====================================================================================================================
 * Provide the local storage management between client and node server
 *
 */
export const state = () => ({
  authProcessRedirectLink: null,
});

export const mutations = {
  setAuthProcessRedirectLink (state: any, link: string | null) {
    state.authProcessRedirectLink = link;
  },
};
