// eslint-disable-next-line import/named
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Alert, WindowSizes, SunsetDataParams } from '~/@types/meta';

interface StateInterface {
  alerts: Alert[]; // Display main logo or page title
  alertIdCounter: number;
  isCartDrawerOpen: boolean;
  windowInner: WindowSizes;
  hasScrolled: boolean; // Has the website initially scrolled.
  latestQueryId: string | null;
  isMobileMainMenuOpen: boolean;
}

/**
 * States
 */
export const state = (): StateInterface => ({
  alerts: [],
  alertIdCounter: 0,
  isCartDrawerOpen: false,
  windowInner: {
    height: null,
    width: null,
  },
  hasScrolled: false,
  latestQueryId: null,
  isMobileMainMenuOpen: false,
});

/**
 * Actions
 */
export const actions: ActionTree<StateInterface, any> = {
  /**
   * Get system updates
   *
   * @param commit
   * @param state
   * @param loginData
   * @constructor
   */
  GET_SYSTEM_UPDATES ({ commit, state }, loginData: any): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api.get('/system/updates', loginData).then((response) => {
        resolve(response);
      });
    });
  },

  /**
   * Toggle mobile main menu open close state
   *
   * @param commit
   * @param state
   * @param isMobileMainMenuOpen
   * @constructor
   */
  TOGGLE_MOBILE_MAIN_MENU_OPEN_CLOSE (
    { commit, state },
    isMobileMainMenuOpen: boolean,
  ): any {
    commit('SetMobileMainMenuOpenClose', isMobileMainMenuOpen);
  },

  /**
   * Toggle mobile main menu open close state
   *
   * @param commit
   * @param state
   * @param sunsetDataParams
   * @constructor
   */
  GET_SUNSET_DATA ({ commit, state }, sunsetDataParams: SunsetDataParams): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api.get('/sunset/', sunsetDataParams).then((response) => {
        // Display success/error
        if (response.code !== 200) {
          // @ts-ignore
          (this as any).$alert.show(
            response.data.data || 'Oops! something went wrong.',
            'error',
          );
        }
        resolve(response);
      });
    });
  },
};

/**
 * Mutations
 */
export const mutations: MutationTree<StateInterface> = {
  setAlert (state, alerts: Alert[]) {
    state.alerts = alerts;
  },
  setAlertIdCounter (state, countingValue: number) {
    state.alertIdCounter += countingValue;
  },
  toggleCartDrawerOpen (state, isCartDrawerOpen: boolean) {
    state.isCartDrawerOpen = isCartDrawerOpen;
  },
  setWindowWidthAndHeight (state, windowSizeData: WindowSizes) {
    state.windowInner = {
      width: windowSizeData.width,
      height: windowSizeData.height,
    };
  },
  setHasScrolled (state, hasScrolled: boolean) {
    state.hasScrolled = hasScrolled;
  },
  setLatestQueryId (state, queryId: string | null) {
    state.latestQueryId = queryId;
  },
  SetMobileMainMenuOpenClose (state, isMobileMainMenuOpen: boolean) {
    state.isMobileMainMenuOpen = isMobileMainMenuOpen;
  },
};

/**
 * Getters
 */
export const getters: GetterTree<StateInterface, any> = {
  // isLoggedIn (state): boolean {
  //   return state.isLoggedIn;
  // },
};
