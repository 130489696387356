// eslint-disable-next-line import/named
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { ExamTemplate } from '~/@types/exam';
import { FetchingParams, PaginatedResponse } from '~/@types/meta';

interface searchQuery {
  searchQuery: string;
  showArchived: boolean;
}
interface StateInterface {
  examTemplateResponse: PaginatedResponse<ExamTemplate>;
  query: searchQuery;
  isFetching: boolean;
  fetchingParams: FetchingParams;
}

/**
 * States
 */
export const state = (): StateInterface => ({
  examTemplateResponse: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: null,
    next_page_url: null,
    per_page: 15,
    to: 0,
    total: 0,
  },
  isFetching: false,
  fetchingParams: {
    perPage: 15,
    page: 1,
    orderBy: [],
    orderDesc: [],
  },
  query: {
    searchQuery: '',
    showArchived: false,
  },
});

/**
 * Actions
 */
export const actions: ActionTree<StateInterface, any> = {
  GET_TEMPLATES ({ commit, state }, questions: any): any {
    const queryParams = {
      ...state.fetchingParams,
      ...state.query,
    };

    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api.get('/templates', queryParams).then((response) => {
        if (response.code !== 200) {
          // @ts-ignore
          (this as any).$alert.show(
            'Something went wrong while getting Exam Templates.',
            'error',
          );
          resolve(null);
        } else {
          commit('storeTemplates', response.data);
        }
      });
    });
  },
};

/**
 * Mutations
 */
export const mutations: MutationTree<StateInterface> = {
  storeTemplates (state: any, response: any[] | null) {
    state.examTemplateResponse = response;
  },
  setSearchData (state: StateInterface, searchData) {
    state.query.searchQuery = searchData.search;
    state.query.showArchived = searchData.showArchived;
  },
  setIsFetching (state, isUsersFetching: boolean) {
    state.isFetching = isUsersFetching;
  },
  setFetchParams (state: StateInterface, fetchingParams: FetchingParams) {
    state.fetchingParams = fetchingParams;
  },
};
/**
 * Getters
 */
export const getters: GetterTree<StateInterface, any> = {
  // isLoggedIn (state): boolean {
  //   return state.isLoggedIn;
  // },
};
