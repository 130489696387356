export default function (context) {
  // if (
  //   context.query.hasOwnProperty('utm_source') &&
  //   context.query.hasOwnProperty('utm_medium')
  // ) {
  //   const utmSource = context.query['utm_source'];
  //   const utmMedium = context.query['utm_medium'];
  // } else {
  //   if (
  //     context.req.headers['referer'].includes(
  //       'https://www.medvieweducation.org'
  //     )
  //   ) {
  //     const utmSource = 'o_content';
  //     const utmMedium = 'website';
  //   } else {
  //     const utmSource = 'o_search';
  //     const utmMedium = 'organic_search';
  //   }
  // }
}
