import { Context } from '@nuxt/types';

export default (context: Context) => {
  // Check if user is connected first

  // Get authorizations for matched routes (with children routes too)
  const metaIndex = (context as any).route.meta.findIndex(
    (el: any) => !!el.allowedRoles,
  );
  const meta = (context as any).route.meta[metaIndex];
  if (meta.allowedRoles) {
    const user = (context as any).store.state.myAccount.user;
    let isRole = -2;
    isRole = meta.allowedRoles.findIndex((el: any) => {
      return user && el === user.role_id;
    });

    if (isRole === -1) {
      if (process.client) {
        context.$alert.show(
          'You do not have permission to view this page.',
          'error',
        );
      }

      if (meta.redirectTo !== null && meta.redirect !== undefined) {
        // @ts-ignore
        return context.redirect(meta.redirectTo);
      }
      // @ts-ignore
      return context.redirect('/');
    }
  }
  return 0;
};
