
import { Component, Vue } from 'vue-property-decorator';
import Alerts from '@components/alerts/index.vue';
import layoutHeader from '~/layouts/layout-alpha/layout-alpha-guest/header/index.vue';

@Component({
  components: {
    Alerts,
    layoutHeader,
  },
})
export default class layoutAlphaGuest extends Vue {}
