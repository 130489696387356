export default ({ store, redirect, route, auth }) => {
  if (
    store.myaccount.user !== null &&
    route.name !== 'invitation' &&
    route.name !== 'sign-in'
  ) {
    if (process.client) {
      return redirect('/auth/sign-in');
    }
  }
};
