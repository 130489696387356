import { Context } from '@nuxt/types';
import { ApiReturn } from '../api-service';
import { StudentUser } from '~/@types/auth';

export const studentFactory = (context: Context) => ({
  /**
   * GET students
   *
   * @param url
   * @param params
   */
  async get(): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.get('/students');
  },

  /**
   * GET single student
   *
   * @param url
   * @param params
   */
  show(id: number): Promise<ApiReturn> {
    // Make the api call
    return context.$api.get(`/students/${id}`);
  },

  /**
   * Store student Request
   *
   * @param body
   */
  store(body: any) {
    // Make the api call
    return context.$api.post('/users/student', body);
  },

  /**
   * Student SELF register
   *
   * @param body
   */
  register(body: any) {
    // Make the api call
    return context.$api.post('/auth/register', body);
  },

  /**
   * Store student Request
   *
   * @param body
   */
  async update(id: number, body: any): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.put(`/students/${id}`, body);
  },

  updateContacts(student: StudentUser) {
    return context.$api.put(
      `/students/${student.id}/contacts`,
      student.contacts,
    );
  },

  /**
   * Store student Request
   *
   * @param body
   */
  async archive(id: number): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.delete(`/students/${id}`);
  },

  getYearLevels() {
    return context.$api.get('/dictionaries/year_levels');
  },

  /**
   * GET subscription history
   *
   * @param url
   * @param params
   */
  getSubscriptionHistory(id: number): Promise<ApiReturn> {
    return context.$api.get(`/students/${id}/subscriptions`);
  },

  /**
   * GET subscription history
   *
   * @param url
   * @param params
   */
  getReports(): Promise<ApiReturn> {
    return context.$api.get(`/reports`);
  },

  /**
   * GET subscription history
   *
   * @param url
   * @param params
   */
  upgradeToCrimsonPlan(id: number): Promise<ApiReturn> {
    return context.$api.post(`/students/${id}/upgrade`);
  },

  /**
   * Create subscription
   *
   * @param url
   * @param params
   */
  async createSubscription(student_id: string, body: any): Promise<ApiReturn> {
    return await context.$api.post(
      `/students/${student_id}/subscriptions`,
      body,
    );
  },

  /**
   * Store student tutors request
   *
   * @param body
   */
  async updateStudentTutors(student_id: number, body: any): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.put(`/students/${student_id}/tutors`, body);
  },

  /**
   * Store student EduCo request
   *
   * @param body
   */
  async updateStudentEduCo(student_id: number, body: any): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.put(`/students/${student_id}/eduCo`, body);
  },
});
