import { Context } from '@nuxt/types';
import { SiteConfig } from '~/@types/meta';

const config: SiteConfig = {
  appName: process.env.APP_NAME!,
  apiUrl: process.env.BASE_URL!,
  appUrl: process.env.APP_URL!,
};

const student = {
  discounts: [
    { value: 0, text: '0%' },
    { value: 25, text: '25%' },
    { value: 50, text: '50%' },
    { value: 100, text: '100%' },
  ],
};
/*
 ** Executed by ~/.nuxt/index.js with context given
 ** This method can be asynchronous
 */
export default (context: Context, inject: any) => {
  // Inject api service as a global nuxt module. You can access using $api.get('blah/blah', {params}) etc...
  inject('constants', {
    config,
    student,
  });
};
