import ROLES from '~/constants/roles';

export default function ({ store, redirect, route, $moment, $auth }) {
  const user = store.state.myAccount.user;

  // console.log(route.fullPath);
  // if (user) {
  //   const signedInAt = $moment(user.signed_in_at);
  //   const duration = $moment.duration($moment().diff(signedInAt)).as('seconds');
  //   console.log(user, duration);
  //   if (duration >= 5 * 60 && route.fullPath !== 'auth/sign-in') {
  //     await $auth.logout();
  //     return redirect('/auth/sign-in');
  //   }
  // }

  // console.log('store', user);
  // console.log('route', route);
  // if (store.state.auth.loggedIn && !user) {
  //   await store.dispatch('myAccount/GET_USER_UPDATES');
  // }

  if (
    route.fullPath !== '/onboarding' &&
    !!user &&
    (user as any).profile_completed_at === null &&
    (user as any).role_id === ROLES.ROLE_STUDENT
  ) {
    return redirect('/onboarding');
  }

  // if (
  //   route.fullPath !== '/payment/subscription' &&
  //   user &&
  //   (user as any).profile_completed_at &&
  //   (user as any).role_id === ROLES.ROLE_STUDENT &&
  //   !(user as any).is_subscribed &&
  //   !(user as any).is_on_trial
  // ) {
  //   return redirect('/payment/subscription');
  // }
}
