
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'nuxt-class-component';
import Alerts from '@components/alerts/index.vue';
import { WindowSizes } from '~/@types/meta';
import { User } from '~/@types/auth';
import layoutAlphaGuest from '~/layouts/layout-alpha/layout-alpha-guest/index.vue';
import layoutAlphaAuth from '~/layouts/layout-alpha/layout-alpha-auth/index.vue';

@Component({
  components: {
    layoutAlphaGuest,
    layoutAlphaAuth,
    Alerts,
  },
})
export default class QuestionVertical extends Vue {
  @State('user', { namespace: 'auth' }) public user!: User | null;
  @State('loggedIn', { namespace: 'auth' }) public loggedIn!: boolean | null;

  /**
   * Component MOUNTED!
   *
   * @private
   */
  public mounted () {
    if (process.browser) {
      // Call the first time to initiate values
      (this as any).onWindowResized();
      (this as any).$nextTick(() => {
        window.addEventListener('resize', (this as any).onWindowResized);
      });
    }
  }

  /**
   * Update inner height when the window inner height changed
   */
  public onWindowResized () {
    const windowSize: WindowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    (this as any).$store.commit('meta/setWindowWidthAndHeight', windowSize);
  }
}
