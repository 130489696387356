/**
 * API Service
 * =====================================================================================================================
 * Provides the api service for the application using axios.
 *
 */
import { Context } from '@nuxt/types';
import { ApiReturn } from '~/plugins/api-service/api-service';

// eslint-disable-next-line no-unused-vars
enum CODES {
  // eslint-disable-next-line no-unused-vars
  UNAUTHORIZED = 401,
}

class ApiService {
  private axios: any;
  private context: Context;

  constructor(axios: any, context: Context) {
    this.axios = axios;
    this.context = context;
  }

  /**
   * Checks the token expiration date and forces the user to refresh token
   */
  public checkToken(route = null): Promise<boolean> {
    // check auth0 exp date
    const tokenExpiresAt: any = this.context.$cookies.get(
      'auth._token_expiration.auth0',
    );

    if (tokenExpiresAt && process.client) {
      try {
        const tokenExpiresAtUnix = this.context.$moment(tokenExpiresAt).unix();
        const nowUnix = this.context.$moment().unix();

        // if less than 3 hours. log user out with a message.
        if (tokenExpiresAtUnix - nowUnix < 10800) {
          const currentPath = this.context.route.fullPath; // Capture the current URL path
          const redirectURL = `/auth/refresh?next=${encodeURIComponent(currentPath)}`; // Construct the redirect URL

          this.context.redirect(route ?? redirectURL); // Redirect to the login page

        }
        return Promise.resolve(true);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }
  }

  /**
   * Gets the current user
   */
  user() {
    return this.context.store.app.store.state.myAccount.user;
  }

  /**
   * GET Request
   *
   * @param url
   * @param params
   */
  async get(url: string, params: any = null): Promise<ApiReturn> {
    // Make the api call
    try {
      const response = await this.axios.get(url, { params });
      return this.returnData(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * POST Request
   *
   * @param url
   * @param data
   */
  async post(url: string, data: any, config: any = {}) {
    // Make the api call
    try {
      const response = await this.axios.post(url, data, config);
      return this.returnData(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * PUT Request
   *
   * @param url
   * @param data
   */
  async put(url: string, data: any) {
    // Make the api call
    try {
      const response = await this.axios.put(url, data);
      return this.returnData(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * DELETE Request
   *
   * @param url
   * @param params
   */
  async delete(url: string, params: any): Promise<ApiReturn> {
    // Make the api call
    try {
      const response = await this.axios.delete(url, { params });
      return this.returnData(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * Structures the success return data
   *
   * This will setup a structured object of every api return inside the app
   * @param response
   */
  returnData(response: any) {
    const data = {
      code: response.data.code || 200,
      data: response.data.data,
    };
    return data;
  }

  /**
   * This will setup a structured object of every api return inside the app
   * @param error
   */
  async handleError(error: any) {
    // removing 400, 401, 403, 422 for the time being. We will add them back in
    // once we have a better idea of how they affect other issues
    const nonCriticalErrors = [400, 401, 403, 422];

    const errorStatus = error.response ? error.response.status || -1 : -1;
    let errorMessage = 'Unknown Server Error';

    if (error.response && error.response.data) {
      // If there's an error message in the response, use it.
      errorMessage = error.response.data.message || errorMessage;
    }

    if (errorStatus === CODES.UNAUTHORIZED) {
      if (process.client) {
        await this.context.$auth.refreshTokens();
        this.context.redirect('/');
        return;
      }
    } else if (!nonCriticalErrors.includes(errorStatus)) {
      // Only record non-critical errors in Sentry
      if (error.response) {
        this.context.$sentry.captureException(new Error(errorMessage), {
          extra: {
            error,
          },
        });
      }
    }

    return Promise.reject(new Error(errorMessage));
  }


  /**
   * This method checks if the user is currently logged in
   */
  isUserLoggedIn(): boolean {
    return (
      this.context.$moment().unix() <
      this.context.$cookies.get('auth._token_expiration.auth0')
    );
  }
}

/*
 ** Executed by ~/.nuxt/index.js with context given
 ** This method can be asynchronous
 */
export default (context: Context, inject: any) => {
  const $axios = context.$axios;
  const api = new ApiService($axios, context);

  // Get website origin
  const locationOrigin = context.req
    ? context.req.headers.host
    : window.location.origin;

  // Setup request headers
  $axios.setHeader('Accept', 'application/json');
  $axios.setHeader('Content-Type', 'application/json');
  $axios.setHeader('Access-Control-Allow-Origin', locationOrigin);

  // Set access token (require to access api base)
  const tokenData: any = context.$cookies.get('access-token-data');
  if (tokenData && tokenData.key && tokenData.token) {
    $axios.setHeader('Access-Key', tokenData.key.toString());
    $axios.setHeader('Access-Token', tokenData.token);
  }

  // Inject api service as a global nuxt module.
  inject('api', api);
};
