// eslint-disable-next-line import/named

// eslint-disable-next-line import/named
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import moment from 'moment';
import {
  ITimeLimit,
  Module,
  Question,
  QuestionField,
  Section,
} from '~/@types/exam';
import { StandardSingleResponse } from '~/@types/meta';
import { ExamResult, PracticeSession, CurrentTimer } from '~/@types/practice';
interface StateInterface {
  isSectionSaving: boolean;
  results: any[];
  modules: Module[];
  filteredQuestions: Question[];
  sections: Section[];
  exam: PracticeSession | null;
  moduleIndex: number;
  sectionIndex: number;
  timeLimits: ITimeLimit[];
  currentTimer: CurrentTimer;
}

/**
 * States
 */
export const state = (): StateInterface => ({
  isSectionSaving: false,
  exam: null,
  results: [],
  modules: [],
  filteredQuestions: [],
  sections: [],
  currentTimer: {
    startedAt: null,
    finishedAt: null,
    questionId: null,
  },
  moduleIndex: -1, // negative 1 is intro page.
  sectionIndex: -1, // negative 1 is intro page.
  timeLimits: [
    {
      time_limit: -1,
      started_at: moment().format('YYYY-MM-DD hh:mm:ss'),
      finished_at: null,
      code: 'no_limit',
    },
  ], // negative 1 is intro page.
});

/**
 * Actions
 */
export const actions: ActionTree<StateInterface, any> = {
  STORE_RESULTS(store, result: any): any {
    store.commit('storeResults', result);
  },
  fetchPracticeSession({ commit, state, dispatch }, hash: string): any {
    if (!hash) {
      // handle hash not found
      return;
    }
    return this.$api
      .get(`practice/${hash}`)
      .then((res: StandardSingleResponse<PracticeSession>) => {
        commit('clearResults');
        commit('setModule', -1);
        if (res.code === 200) {
          if (!res.data.finished_at) {
            res.data.started_at = moment().format('YYYY-MM-DD hh:mm:ss');
          }
          commit('saveExam', res.data);

          if (res.data.finished_at) {
            return dispatch('postExamProcessResults', true);
          }
          dispatch('preExamProcessResults', true);
        }
      });
  },
  completePracticeExam({ commit, dispatch }, id: number): any {
    return this.$api.post(`exam/${id}/end`, {
      finished_at: moment().format('YYYY-MM-DD hh:mm:ss'),
    });
  },
  fetchPracticeExam({ commit, dispatch }, hash: string): any {
    return this.$api
      .get(`exam/${hash}`)
      .then((res: StandardSingleResponse<PracticeSession>) => {
        commit('clearResults');
        commit('setModule', -1);
        if (res.code === 200) {
          if (!!res.data.started_at && !res.data.finished_at) {
            this.$alert.show(
              'This exam has been started, but was not finished, there are no results to show.',
              'info',
            );
            return this.$router.push('/');
          }

          if (!res.data.finished_at) {
            res.data.started_at = moment().format('YYYY-MM-DD hh:mm:ss');

            this.$apiFactories.practice.ExamStarted(
              res.data.id,
              res.data.started_at,
            );
          }

          commit('saveExam', res.data);

          if (res.data.finished_at) {
            return dispatch('postExamProcessResults', true);
          }
          dispatch('preExamProcessResults', true);
        }
      })
      .catch((err: any) => {
        // error
        if (err.response?.status === 401 || err.response?.status === 403) {
          // unauthorized
          this.$alert.show(
            'You are not authorized to view this exam.',
            'error',
          );
        } else {
          this.$alert.show('An unknown error occurred.', 'error');
          this.$router.back();
        }
      });
  },
  endPracticeSession(
    { state }: { state: StateInterface },
    { exam }: { exam: PracticeSession },
  ) {
    return this.$apiFactories.practice.End(
      {
        hash: exam.hash,
        type: 'practice',
      },
      {
        exam: {
          started_at: exam.started_at,
          finished_at: exam.finished_at,
        },
        results: state.results,
      },
    );
  },
  async EndSection(
    {
      commit,
      state,
      getters,
    }: {
      commit;
      state: StateInterface;
      getters: GetterTree<StateInterface, any>;
    },
    { exam }: { exam: PracticeSession },
  ) {
    if (state.isSectionSaving) return;


    if (state.sectionIndex === -1 || !state.exam?.sections?.length) {
      this.$sentry.captureException(
        new Error('something went wrong with the section saving'),
        {
          extra: {
            practice_state: state,
            exam,
          },
        },
      );
      return;
    }
    if (getters.Results.length === 0) {
      this.$sentry.captureException(
        new Error('No results were in the store...'),
        {
          extra: {
            practice_state: state,
            exam,
          },
        },
      );
      // return home
      this.$router.push('/');
      return this.$alert.show(
        'No results were saved in this section',
        'warning',
      );
    }

    commit('setSectionSaving', true);
    const section_id = state.exam.sections![state.sectionIndex].id;
    try {
      const response = await this.$apiFactories.practice.EndSection(
        exam.hash,
        {
          section: {
            section_id,
            started_at: exam.sections![state.sectionIndex].pivot!.started_at,
            finished_at: exam.sections![state.sectionIndex].pivot!.finished_at,
          },
          results: state.results.filter((el) => el.section_id === section_id),
        },
      );
      commit('setSectionSaving', false);
      return response;
    } catch (error) {
      this.$sentry.captureException(new Error("section saving on end-section:" + error.message), error);
      commit('setSectionSaving', false);
      return null;
    }

  },

  preExamProcessResults({ commit, state }, loadDefault = false): any {
    commit('clearAll');
    state.exam!.modules!.forEach((module: Module) => {
      (module.questions as Question[]).forEach((question: Question) => {
        const result: ExamResult = {
          moduleHash: module.hash,
          module_id: module.id,
          section_id: module.section_id,
          questionHash: question.hash,
          question_id: question.id,
          time: 0,
          answers: question.layout.fields.map((el: { answer_id: any }) => {
            return {
              answer: null,
              correct: null,
              answer_id: el.answer_id,
            };
          }),
          isFlagged: false,
          isCorrect: false,
          isAnswered: false,
          isPageSeen: false,
        };

        commit('storeResults', result);
      });
    });
  },
  async saveSectionResults(
    {
      commit,
      state,
      getters,
    }: {
      commit;
      state: StateInterface;
      getters: GetterTree<StateInterface, any>;
    },
    exam,
  ) {

    if (state.isSectionSaving) return;

    if (state.sectionIndex === -1 || !state.exam?.sections?.length) {
      this.$sentry.captureException(
        new Error('something went wrong with the section saving'),
        {
          extra: {
            practice_state: state,
            exam,
          },
        },
      );
      return;
    }
    if (getters.Results.length === 0) {
      return this.$sentry.captureException(
        new Error('No results were in the store...'),
        {
          extra: {
            practice_state: state,
            exam,
          },
        },
      );
    }

    commit('setSectionSaving', true);
    const section_id = state.exam.sections![state.sectionIndex].id;
    try {

      const response = await this.$apiFactories.practice.SaveSectionResults(
        exam.hash,
        {
          section: {
            section_id,
            started_at: exam.sections![state.sectionIndex].pivot!.started_at,
          },
          results: state.results.filter((el) => el.section_id === section_id),
        },
      );
      commit('setSectionSaving', false);
      return response;
    } catch (error) {
      this.$sentry.captureException(new Error("section saving on timer:" + error.message), error);
      commit('setSectionSaving', false);
      return null;
    }

  },

  postExamProcessResults({ commit, state }, loadDefault = false): any {
    const exam = JSON.parse(JSON.stringify(state.exam));

    exam.modules!.forEach((module: Module) => {
      (module.questions as Question[]).forEach((question: Question) => {
        const dbResult: ExamResult = exam!.results.find(
          (_result: { question_id: number | null | undefined }) => {
            return _result.question_id === question.id;
          },
        );

        const result: ExamResult = {
          moduleHash: module.hash,
          module_id: module.id,
          section_id: dbResult?.section_id || undefined,
          sectionHash: dbResult?.sectionHash || undefined,
          questionHash: question.hash,
          question_id: question.id,
          time: dbResult?.time || 0,
          answers: dbResult?.answers || [],
          isFlagged: dbResult?.isFlagged || false,
          isCorrect: dbResult?.isCorrect || false,
          isAnswered: dbResult?.isAnswered || false,
          isPageSeen: true,
          type: dbResult?.type || '',
        };

        commit('storeResults', result);

        question.is_flagged = dbResult?.isFlagged;
        question.layout.fields.forEach(
          (questionField: QuestionField, index: number) => {
            let answer = dbResult?.answers?.find?.((_answer: any) => {
              return (
                _answer.answer_id === questionField.answer_id &&
                _answer.answer_id !== undefined
              );
            });

            if (!answer) {
              answer = dbResult?.answers[index];
            }

            if (questionField) {
              questionField.answer = answer?.answer || null;
            }
          },
        );
      });
    });
    setTimeout(() => {
      commit('saveExam', exam);
    }, 50);
  },
  flagQuestion({ commit, state }, { module_id, question_id }): any {
    const result = state.results.find((el: ExamResult) => {
      return el.module_id === module_id && el.question_id === question_id;
    });

    if (result) {
      commit('setFlagged', {
        module_id,
        question_id,
        isFlagged: !result.isFlagged,
      });
    }

    return Promise.resolve(result);
  },
  setupTimers({ commit }, timers) {
    commit('storeTimers');
  },
  async setTimeOnQuestion({ state, commit }, perQuestionTimer: any) {
    await setTimeout(() => {
      if (!state.results) {
        window.location.href = '/';
        this.$sentry.captureException(
          new Error('No results in results table. exiting!'),
        );
        alert('Something has gone wrong, please message the Crimson team.');
        return;
      }
      const _result: ExamResult = state.results.find((result: ExamResult) => {
        return result.question_id === perQuestionTimer.questionId;
      });
      const result = JSON.parse(JSON.stringify(_result));

      if (result) {
        // if (!result.time) {
        //   result.time = 0;
        // }
        const time = perQuestionTimer.finishedAt - perQuestionTimer.startedAt;
        if (!isNaN(time)) {
          result.time = result.time + time;

          commit('storeResults', result);
        }
      }
    }, 100);
  },
  startTimer({ commit }, questionId) {
    commit('startTimer', questionId);
  },
  async stopTimer({ commit, state, dispatch }) {
    if (!state.currentTimer.questionId) {
      return;
    }
    commit('stopTimer');
    await dispatch('setTimeOnQuestion', state.currentTimer);
    // clear current timer
    commit('clearTimer');
  },
  getTimer({ state }) {
    return state.currentTimer;
  },
};

/**
 * Mutations
 */
export const mutations: MutationTree<StateInterface> = {
  startTimer(state, questionId) {
    state.currentTimer.questionId = questionId;
    state.currentTimer.startedAt = moment();
  },
  stopTimer(state) {
    state.currentTimer.finishedAt = moment();
  },
  clearTimer(state) {
    state.currentTimer = {
      startedAt: null,
      finishedAt: null,
      questionId: null,
    };
  },
  setSectionSaving(state: StateInterface, isSaving: boolean) {
    state.isSectionSaving = isSaving;
  },
  storeResults(state: StateInterface, result: ExamResult | null) {
    const results = state.results;

    const index = results.findIndex(
      (el) => el.question_id === result?.question_id,
    );

    if (index === -1) {
      results.push(result);
    } else {
      results[index] = result;
    }
    state.results = results;
  },
  clearResults(state: StateInterface) {
    state.results = [];
  },
  setModule(state, index = -1) {
    state.moduleIndex = index;
  },
  loadModules(state: StateInterface, modules: Module[]) {
    state.modules = modules;
  },
  saveExam(state: StateInterface, exam: PracticeSession) {
    state.exam = exam;
    state.exam!.updated_at = moment().format('YYYY-MM-DD hh:mm:ss');
  },
  clearFilteredQuestions(state: StateInterface) {
    state.filteredQuestions = [];
  },
  setFilteredQuestions(state: StateInterface, results: ExamResult[]) {
    const filtered_questions: Question[] = [];
    const question_ids: any = results.map((el) => {
      return el.question_id;
    });

    if (state.exam) {
      state.exam!.modules!.forEach((module: Module) => {
        module.questions!.forEach((question: Question) => {
          if (question_ids.includes(question.id)) {
            filtered_questions.push(question);
          }
        });
      });
    } else {
      state.modules!.forEach((module: Module) => {
        module.questions!.forEach((question: Question) => {
          if (question_ids.includes(question.id)) {
            filtered_questions.push(question);
          }
        });
      });
    }

    state.filteredQuestions = filtered_questions;
  },
  setFlagged(state: StateInterface, { module_id, question_id, isFlagged }) {
    const result = state.results.find((el) => {
      return el.module_id === module_id && el.question_id === question_id;
    });
    if (result) {
      result.isFlagged = isFlagged;
      // @ts-ignore
      this.commit('practice/storeResults', result);
    }
    if (state.exam) {
      const moduleIndex = state.exam!.modules!.findIndex(
        (el) => el.id === module_id,
      );
      // const module = state.exam!.modules![moduleIndex];

      if (moduleIndex !== -1) {
        const question = state.exam.modules![moduleIndex].questions!.find(
          (question: Question) => question.id === question_id,
        );
        if (question) {
          question.is_flagged = isFlagged;
          // @ts-ignore
          this.commit('practice/saveQuestion', question);
        }
      }
    }
    // else {
    //   const moduleIndex = state.modules!.findIndex(el => el.id === module_id);
    //   const module = state.modules![moduleIndex];
    //   const result = state.results.find((el) => {
    //     return el.module_id === module_id && el.question_id === question_id;
    //   });
    //   if (result) {
    //     result.isFlagged = isFlagged;
    //     this.commit('practice/storeResults', result);
    //   }

    //   if (moduleIndex !== -1) {
    //     const question = state.modules[moduleIndex].questions.find(
    //       (question: Question) => question.id === question_id,
    //     );
    //     if (question) {
    //       question.is_flagged = isFlagged;
    //       this.commit('practice/saveQuestion', question);
    //     }
    //   }
    // }
  },
  saveQuestion(state: StateInterface, question) {
    const module_index = state.exam!.modules!.findIndex((_module) => {
      return _module.id === question.module_id;
    });
    const question_index = state.exam!.modules![
      module_index
    ].questions!.findIndex((_question) => {
      return _question.id === question.id;
    });
    state.exam!.modules![module_index].questions![question_index] = question;
    state.exam!.updated_at = moment().format('YYYY-MM-DD hh:mm:ss');
  },
  setTimers(state, timers) {
    state.timeLimits = timers;
  },
  setSection(state, sectionIndex) {
    state.sectionIndex = sectionIndex;
  },
  bumpSectionIndex(state) {
    state.sectionIndex += 1;
    if (
      state.sectionIndex >= 0 &&
      state.sectionIndex < state.exam!.sections!.length
    ) {
      state.exam!.sections![state.sectionIndex].pivot!.started_at = (
        this as any
      )
        .$moment()
        .format('YYYY-MM-DD hh:mm:ss');
    }
  },
  clearAll(state: StateInterface) {
    state.sectionIndex = -1;
    state.moduleIndex = -1;
    state.results = [];
    state.filteredQuestions = [];
  },
};
/**
 * Getters
 */
export const getters: GetterTree<StateInterface, any> = {
  PracticeSession(state): PracticeSession {
    return state.exam!;
  },
  Results(state): ExamResult[] {
    if (!state.exam!.sections || !state.exam!.sections.length) {
      return state.results;
    }
    return state.results.filter((result: ExamResult) => {
      return (
        state.sectionIndex !== -1 &&
        result.section_id === state.exam!.sections![state.sectionIndex].id
      );
    });
  },
  Modules(state): Module[] {
    return state.modules ? state.modules : [];
  },
  currentModule(state): Module | null {
    return state.modules ? state.modules[state.moduleIndex] : null;
  },
  moduleIndex(state): number {
    return state.moduleIndex;
  },
  sectionIndex(state): number {
    return state.sectionIndex;
  },
  timers(state): ITimeLimit[] {
    return state.timeLimits;
  },
  currentTimer(state): CurrentTimer {
    return state.currentTimer;
  },
};
