import { Context } from '@nuxt/types';
import { TimerInterface } from '~/@types/exam';
import ROLES from '~/constants/roles';


const Timer = (context: Context): TimerInterface => ({
  start(params: { id: string; type: string; isReview: boolean }) {
    if (
      context.$auth.user &&
      context.$auth.user.role_id === ROLES.ROLE_STUDENT
    ) {
      context.store.commit('activeTimer/StartTimer', {
        meta_id: params.id,
        type: params.type,
        isReview: params.isReview,
      });
    }
  },

  end: async () => {
    if (
      context.$auth.user &&
      context.$auth.user.role_id === ROLES.ROLE_STUDENT
    ) {
      context.store.commit('activeTimer/EndTimer');

      const timer = context.store.state.activeTimer.timer;

      if (!timer || !timer.started_at) {
        return;
      }

      try {
        await context.$api
          .post('stats/timer', {
            timer: {
              started_at: timer.started_at,
              finished_at: timer.finished_at,
              meta_id: timer.meta_id,
              meta_type: timer.type,
              isReview: timer.isReview,
            },
          });
      } catch (e) {
        // can fail silently
        console.error('Timer error', e);

        // context.$sentry.captureException(new Error('Timer error'), {
        //   extra: {
        //     error: e,
        //   },
        // });
      }

      context.store.commit('activeTimer/ClearTimer');
    }
  },
});

export default (context: Context, inject: any) => {
  inject('timer', Timer(context));
};