const middleware = {}

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['redirect'] = require('../middleware/redirect.ts')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['tracking'] = require('../middleware/tracking.ts')
middleware['tracking'] = middleware['tracking'].default || middleware['tracking']

middleware['withRole'] = require('../middleware/withRole.ts')
middleware['withRole'] = middleware['withRole'].default || middleware['withRole']

export default middleware
