
import { Component, Vue } from 'vue-property-decorator';
import Alerts from '@components/alerts/index.vue';
// import {Context} from '@nuxt/types';
import { Action } from 'vuex-class';
import layoutHeader from '~/layouts/layout-alpha/layout-alpha-auth/header/index.vue';
import layoutFooter from '~/layouts/layout-alpha/layout-alpha-auth/footer/index.vue';
import { ApiDictionary, StandardResponse } from '~/@types/meta';
// import {StandardResponse} from '~/@types/meta';

@Component({
  components: {
    layoutHeader,
    layoutFooter,
    Alerts,
  },
})
export default class layoutAlphaAuth extends Vue {
  public isStudent: boolean = true;
  public collapsedNav: boolean = false;
  @Action('GET_USER_UPDATES', { namespace: 'myAccount' })
  public GET_USER_UPDATES!: any;

  async mounted () {
    const response: StandardResponse<ApiDictionary> = await (
      this as any
    ).$api.get('dictionaries/student_status');
    if (response.code === 200) {
      (this as any).$store.commit('user/setStudentStatuses', response.data);
    }
  }

  public created (): void {
    (this as any).GET_USER_UPDATES();

    // @ts-ignore
    (this as any).$nuxt.$on('isStudent', (result: any) => {
      (this as any).isStudent = result;
    });
  }
}
