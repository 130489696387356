
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'nuxt-class-component';
import { Alert } from '~/@types/meta';

@Component({
  components: {
    //
  },
})
export default class Alerts extends Vue {
  @State('alerts', { namespace: 'meta' }) public alerts!: Alert[];

  /**
   * Dismiss the alert
   *
   * @param alertId
   * @private
   */
  public dismissAlert (alertId: number) {
    (this as any).$alert.dismiss(alertId);
  }
}
