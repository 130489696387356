import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import { rolesFactory } from './factories/roles';
import { salesforceFactory } from './factories/salesforce';
import { studentFactory } from './factories/students';
import { userFactory } from './factories/users';
import { examFactory } from './factories/exam';
import { systemFactory } from './factories/system';
import { questionFactory } from './factories/question';
import { PracticeFactory } from './factories/practice';

const factories = (context: Context) => ({
  roles: rolesFactory(context),
  student: studentFactory(context),
  users: userFactory(context),
  salesforce: salesforceFactory(context),
  exams: examFactory(context),
  questions: questionFactory(context),
  system: systemFactory(context),
  practice: PracticeFactory(context),
});

/*
 ** Executed by ~/.nuxt/index.js with context given
 ** This method can be asynchronous
 */
export default (context: Context, inject: Inject) => {
  const apiFactory = factories(context);
  // Inject api service as a global nuxt module. You can access using $api.get('blah/blah', {params}) etc...
  inject('apiFactories', apiFactory);
};
