// eslint-disable-next-line import/named
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Exam } from '~/@types/exam';
import { FetchingParams, PaginatedResponse } from '~/@types/meta';

interface searchQuery {
  searchQuery: string;
  showArchived: boolean;
  orderedOnly?: boolean;
}

interface StateInterface {
  examsResponse: PaginatedResponse<Exam>;
  query: searchQuery;
  isFetching: boolean;
  fetchingParams: FetchingParams;
}

/**
 * States
 */
export const state = (): StateInterface => ({
  examsResponse: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: null,
    next_page_url: null,
    per_page: 15,
    to: 0,
    total: 0,
  },
  isFetching: false,
  fetchingParams: {
    perPage: 15,
    page: 1,
    orderBy: [],
    orderDesc: [],
  },
  query: {
    searchQuery: '',
    showArchived: false,
    orderedOnly: false,
  },
});

/**
 * Actions
 */
export const actions: ActionTree<StateInterface, any> = {
  GET_EXAMS ({ commit, state }, questions: any): any {
    const queryParams = {
      ...state.fetchingParams,
      ...state.query,
    };

    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api.get('/exams', queryParams).then((response) => {
        if (response.code !== 200) {
          // @ts-ignore
          (this as any).$alert.show(
            'Something went wrong while getting Exam Templates.',
            'error',
          );
          resolve(null);
        } else {
          commit('storeTemplates', response.data);
        }
      });
    });
  },

  STORE_EXAM ({ commit, state }, formData: any): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api
        .post('/exams', formData)
        .then((response: any) => {
          if (response.code !== 200) {
            // @ts-ignore
            (this as any).$alert.show(
              'Something went wrong while creating exam.',
              'error',
            );
          }
          resolve(response);
        })
        .catch((response: any) => {
          // @ts-ignore
          (this as any).$alert.show(response.error, 'error');
          resolve(response);
        });
    });
  },

  /**
   * Add new question to a exam
   * @param commit
   * @param state
   * @param formData
   * @constructor
   */
  ADD_QUESTION_TO_EXAM ({ commit, state }, formData: any): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api
        .post('/exams/add-question', formData)
        .then((response: any) => {
          if (response.code !== 200) {
            // @ts-ignore
            (this as any).$alert.show(
              'Something went wrong while creating exam.',
              'error',
            );
          }
          resolve(response);
        })
        .catch((response: any) => {
          // @ts-ignore
          (this as any).$alert.show(response.error, 'error');
          resolve(response);
        });
    });
  },

  /**
   * Add new question to a exam
   * @param commit
   * @param state
   * @param formData
   * @constructor
   */
  REMOVE_QUESTION_FROM_EXAM ({ commit, state }, formData: any): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api
        .post('/exams/remove-question', formData)
        .then((response: any) => {
          if (response.code !== 200) {
            // @ts-ignore
            (this as any).$alert.show(
              'Something went wrong while creating exam.',
              'error',
            );
          }
          resolve(response);
        })
        .catch((response: any) => {
          // @ts-ignore
          (this as any).$alert.show(response.error, 'error');
          resolve(response);
        });
    });
  },

  /**
   * Update question order
   * @param commit
   * @param state
   * @param formData
   * @constructor
   */
  UPDATE_QUESTION_MODULE_ORDER ({ commit, state }, formData: any): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api
        .post('/exams/update-question-order', formData)
        .then((response: any) => {
          if (response.code !== 200) {
            // @ts-ignore
            (this as any).$alert.show(
              'Something went wrong while creating exam.',
              'error',
            );
          }
          resolve(response);
        })
        .catch((response: any) => {
          // @ts-ignore
          (this as any).$alert.show(response.error, 'error');
          resolve(response);
        });
    });
  },

  /**
   * Get question of the exam
   * @param commit
   * @param state
   * @param formData
   * @constructor
   */
  GET_EXAM_QUESTIONS ({ commit, state }, formData: any): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api
        .get('/exams/get-question/' + formData.exam_id, {
          section_id: formData.section_id,
        })
        .then((response: any) => {
          if (response.code !== 200) {
            // @ts-ignore
            (this as any).$alert.show(
              'Something went wrong while creating exam.',
              'error',
            );
          }
          resolve(response);
        })
        .catch((response: any) => {
          // @ts-ignore
          (this as any).$alert.show(response.error, 'error');
          resolve(response);
        });
    });
  },
};

/**
 * Mutations
 */
export const mutations: MutationTree<StateInterface> = {
  storeTemplates (state: any, response: any[] | null) {
    state.examsResponse = response;
  },
  setSearchData (state: StateInterface, searchData) {
    state.query = JSON.parse(JSON.stringify(searchData));
  },
  setIsFetching (state, isUsersFetching: boolean) {
    state.isFetching = isUsersFetching;
  },
  setFetchParams (state: StateInterface, fetchingParams: FetchingParams) {
    state.fetchingParams = fetchingParams;
  },
  clearExams (state: StateInterface, fetchingParams: FetchingParams) {
    state.examsResponse = {
      current_page: 1,
      data: [],
      first_page_url: '',
      from: 0,
      last_page: 0,
      last_page_url: null,
      next_page_url: null,
      per_page: 15,
      to: 0,
      total: 0,
    };
  },
};
/**
 * Getters
 */
export const getters: GetterTree<StateInterface, any> = {
  // isLoggedIn (state): boolean {
  //   return state.isLoggedIn;
  // },
};
