import { Context } from '@nuxt/types';
import { ApiReturn } from '../api-service';

export const PracticeFactory = (context: Context) => ({
  /**
   *
   * @param Practice Config
   * @returns
   */
  async Start(params: any): Promise<ApiReturn> {
    return await context.$api.post('/practice', params);
  },

  async ExamStarted(exam_id: number, started_at: string) {
    return await context.$api.post(`/exam/${exam_id}/started/`, { started_at });
  },

  async End(params: any, data: any): Promise<ApiReturn> {
    if (!params.hash) {
      return Promise.reject(new Error('No exam set.'));
    }
    if (!params.type) {
      params.type = 'practice';
    }
    return await context.$api.post(
      `/scoring/${params.type}/${params.hash}`,
      data,
    );
  },
  /**
   * 
   * @param exam_hash 
   * @param data 
   * @deprecated use SaveSectionResults instead
   * @returns 
   */
  async EndSection(exam_hash: string, data: any): Promise<ApiReturn> {
    try {
      return await context.$api.post(`exam/${exam_hash}/scoring`, data);
    }
    catch (error) {
      return Promise.reject(error);
    }
  },
  async SaveSectionResults(exam_hash: string, data: any): Promise<ApiReturn> {
    try {
      return await context.$api.post(`exam/${exam_hash}/scoring`, data);
    }
    catch (error) {
      return Promise.reject(error);
    }
  },
});
