
import { Component, Vue } from 'vue-property-decorator';
import accountEditAccount from '@components/account/edit-account/index.vue';
import { State } from 'nuxt-property-decorator';
import { NavMenuItem } from '~/@types/meta';
import { User } from '~/@types/auth';
import constants from '~/constants/roles';
@Component({
  components: {
    accountEditAccount,
  },
})
export default class layoutHeader extends Vue {
  @State('user', { namespace: 'auth' }) public user!: User;
  public collapsedNav: boolean = false;
  public showSideNav: boolean = false;
  public isStudent: boolean = true;
  public isEditAccountOpen: boolean = false;
  public isLoadingCheckout: boolean = false;
  /**
   * Get dynamic nav data based on the user role
   *
   * @private
   */
  public get mainNavItems(): NavMenuItem[] {
    const menuItems: NavMenuItem[] = [
      // {
      //   label: 'Dashboard',
      //   link: '/',
      //   isNewTab: false,
      //   icon: 'dashboard.svg',
      // },
    ];
    if ((this as any).$auth.user) {
      if (
        (this as any).$auth.user.role_id === constants.ROLE_STUDENT ||
        (this as any).$auth.user.role_id === constants.ROLE_CONTACT
      ) {
        (this as any).isStudent = true;
        if (
          (this as any).$auth.user.template_id &&
          (this as any).$auth.user.role_id === constants.ROLE_STUDENT &&
          (this as any).$auth.user.diagnostic
        ) {
          menuItems.push({
            label: 'Start a Practice',
            link: '/start-a-practice',
            isNewTab: false,
            icon: '',
            isButton: true,
          });
          // if (!(this as any).$auth.user.is_crimson_student) {
          //   menuItems.push({
          //     label: 'Register for webinar',
          //     link: 'https://www.medvieweducation.org/events/may/get-into-medicine/?utm_source=o_content&utm_medium=website&utm_campaign=NSC_medview_2023-04-24_medview-spark-webinar-button',
          //     isNewTab: true,
          //     icon: '',

          //     isButton: true,
          //     external: true,
          //     color: '#fbaa1a',
          //   });
          // }
        }
        // @ts-ignore
        (this as any).$nuxt.$emit('isStudent', true);
        return menuItems;
      }

      (this as any).isStudent = false;
      // @ts-ignore
      (this as any).$nuxt.$emit('isStudent', false);

      if (
        [constants.ROLE_SUPER_ADMIN, constants.ROLE_EDUCO_TEAM_LEAD].findIndex(
          (el) => el === (this as any).$auth.user.role_id,
        ) !== -1
      ) {
        menuItems.push({
          label: 'Staff',
          link: '/app/users',
          isNewTab: false,
          icon: 'staff.svg',
        });
      }
      if (
        [
          constants.ROLE_SUPER_ADMIN,
          constants.ROLE_EDUCO_TEAM_LEAD,
          constants.ROLE_EDUCO,
          constants.ROLE_TUTOR,
        ].findIndex((el) => el === (this as any).$auth.user.role_id) !== -1
      ) {
        menuItems.push({
          label: 'Dashboard',
          link: '/app',
          isNewTab: false,
          icon: 'exams.svg',
        });
        menuItems.push({
          label: 'Students',
          link: '/app/students',
          isNewTab: false,
          icon: 'students.svg',
        });
      }
      if (
        [constants.ROLE_SUPER_ADMIN].findIndex(
          (el) => el === (this as any).$auth.user.role_id,
        ) !== -1
      ) {
        menuItems.push({
          label: 'Exams',
          link: '/app/exams',
          isNewTab: false,
          icon: 'exams.svg',
        });
        menuItems.push({
          label: 'Exam Order',
          link: '/app/ordering',
          isNewTab: false,
          icon: 'exams.svg',
        });
        menuItems.push({
          label: 'Exam Templates',
          link: '/app/templates',
          isNewTab: false,
          icon: 'exams.svg',
        });
      }
      if (
        [constants.ROLE_SUPER_ADMIN, constants.ROLE_CONTENT_WRITER].findIndex(
          (el) => el === (this as any).$auth.user.role_id,
        ) !== -1
      ) {
        menuItems.push({
          label: 'Exam Questions',
          link: '/app/questions',
          isNewTab: false,
          icon: 'exams.svg',
        });
      }
      // if (
      //   [
      //     constants.ROLE_TUTOR,
      //     constants.ROLE_EDUCO,
      //     constants.ROLE_EDUCO_TEAM_LEAD,
      //   ].findIndex(el => el === (this as any).$auth.user.role_id) !== -1
      // ) {
      //   menuItems.push({
      //     label: 'My Students',
      //     link: '/my/students',
      //     icon: 'students.svg',
      //   });
      // }
    }
    return menuItems;
  }

  public async submit() {
    (this as any).isLoadingCheckout = true;
    const session = await (this as any).$axios.$post('/subscription-checkout', {
      success_url: '/payment/subscription',
      cancel_url: '/payment/cancel',
    });
    (this as any).isLoadingCheckout = false;
    window.location.href = session.url;
  }

  /**
   * Get the menu item that does not contain any children
   * @private
   */
  public get noChildrenMenuItems() {
    if ((this as any).mainNavItems && (this as any).mainNavItems.length > 0) {
      return (this as any).mainNavItems.filter(
        (navItem: NavMenuItem) =>
          !navItem.children || navItem.children.length < 1,
      );
    }
    return (this as any).mainNavItems;
  }

  /**
   * Get the menu items that has children
   *
   * @private
   */
  public get withChildrenMenuItems() {
    if ((this as any).mainNavItems && (this as any).mainNavItems.length > 0) {
      return (this as any).mainNavItems.filter(
        (navItem: NavMenuItem) =>
          navItem.children && navItem.children.length > 0,
      );
    }
    return (this as any).mainNavItems;
  }

  public bgColor(navItem: NavMenuItem) {
    if (!navItem.isButton) {
      return '';
    }
    return navItem.color ?? '#84bdff';
  }

  // Log user out
  public async logout() {
    (this as any).$nuxt.$emit('loggingOut', true);
    if ((this as any).$auth.user.role_id === constants.ROLE_STUDENT) {
      // Segment - Track Sign Out (server side)
      await (this as any).$api
        .post('/segment/track', {
          event: 'sign_out',
          properties: {
            user_id: (this as any).$auth.user.uuid,
            uid: (this as any).$auth.user.uuid,
          },
        })
        .then((res: any) => {
          if (res.code === 200) {
            console.log('segment track success');
          }
        });
    }
    (this as any).$router.push('/');
    await (this as any).$auth.logout();
  }

  // public submit() {
  //   // You will be redirected to Stripe's secure checkout page
  //   (this as any).$refs.checkoutRef.redirectToCheckout();
  // }

  /**
   * Toggle add new client dialog
   *
   * @private
   */
  public toggleEditUserDialog() {
    (this as any).isEditAccountOpen = !(this as any).isEditAccountOpen;
  }

  public get isLinkedContact() {
    return (this as any).$auth.user.role_id === constants.ROLE_CONTACT;
  }
}
