import { Context } from '@nuxt/types';

export const salesforceFactory = (context: Context) => ({
  /**
   * GET Countries
   *
   * @param url
   * @param params
   */
  getCountries(): any {
    return context.$api.get('countries');
  },
});
