/* eslint-disable no-unused-vars */
enum ROLES {
  ROLE_SUPER_ADMIN = 1,
  ROLE_STAFF = 2,
  ROLE_STUDENT = 3,
  ROLE_EDUCO_TEAM_LEAD = 4,
  ROLE_EDUCO = 5,
  ROLE_TUTOR = 6,
  ROLE_CONTENT_WRITER = 7,
  ROLE_CONTACT = 8
}

export default ROLES;
