/**
 * Main vuex store
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * Do not define any state here. Please use modularise stores to setup vuex and import them here
 */
// import {mobileLayout} from '~/layouts/fs-default/mobile/_store/index.ts';

import { Context } from '@nuxt/types';

export const actions = {
  async nuxtServerInit (context: Context) {
    // @ts-ignore

    if ((this as any).$cookies.get('auth-user')) {
      await (context as any).commit(
        'myAccount/setUser',
        // @ts-ignore
        (this as any).$cookies.get('auth-user'),
      );
    }

    // await (context as any).dispatch('questions/FETCH_DIFFICULTIES');
    //   (this as any).$auth.setUser(response.data);
  },
};

// Main vuex state
export const state = () => ({});
export type RootState = ReturnType<typeof state>;
