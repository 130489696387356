import { Context } from '@nuxt/types';
import { ApiReturn } from '../api-service';
import ROLES from '~/constants/roles';
import { User } from '~/@types/auth';

export const userFactory = (context: Context) => ({
  /**
   * GET students
   *
   * @param url
   * @param params
   */
  async get(params: any): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.get('/users', params);
  },

  /**
   * GET single student
   *
   * @param url
   * @param params
   */
  async show(id: number): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.get(`/users/${id}`);
  },

  /**
   * Store student Request
   *
   * @param body
   */
  async store(body: any): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.post('/users', body);
  },

  /**
   * Store student Request
   *
   * @param body
   */
  async archive(id: number): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.delete(`/users/${id}`);
  },

  async getByRole(queryParam: number | number[]) {
    let roles: number[] = [];
    if (!Array.isArray(queryParam)) {
      roles.push(queryParam);
    } else {
      roles = queryParam;
    }
    let queryString = '';
    roles.forEach((role, index) => {
      queryString += index >= 1 ? '&' : '';
      queryString += `role_ids[]=${role}`;
    });
    return await context.$api.get(`/users/simple/?${queryString}&all=true`);
  },

  async export() {
    return await context.$api.get('/users/export');
  },

  async update(user: User): Promise<any> {
    // @ts-ignore
    const self = context.store.state.myAccount.user;
    const allowedRoles = [ROLES.ROLE_SUPER_ADMIN];
    // eslint-disable-next-line eqeqeq
    if (user.role_id && user.role_id == ROLES.ROLE_STUDENT) {
      allowedRoles.push(ROLES.ROLE_EDUCO);
      allowedRoles.push(ROLES.ROLE_EDUCO_TEAM_LEAD);

      if (
        (user as any).tutor_ids &&
        (user as any).tutor_ids.includes(self.id)
      ) {
        allowedRoles.push(ROLES.ROLE_TUTOR);
      }
    }

    if (
      allowedRoles.findIndex((el) => el === self.role_id) !== -1 ||
      self.id === user.id
    ) {
      return await context.$api.put(`/users/${user.id}`, user);
    }
    return await context.$alert.show(
      'You do not have permission to edit this user.',
    );
  },

  async resendInvitation(id: number) {
    return await context.$api.post(`/users/${id}/resendInvite`);
  },

  async validateLinkedContact(body: any): Promise<ApiReturn> {
    return await context.$api.post('/users/linkedContactValidator', body);
  },
});
