import { Context } from '@nuxt/types';
import { ApiReturn } from '../api-service';

export const rolesFactory = (context: Context) => ({
  /**
   * GET Request
   *
   * @param url
   * @param params
   */
  async get (url: string, params: any): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.get('/roles');
  },
});
