/**
 * Alert service
 * =====================================================================================================================
 * Display system alerts
 *
 */
import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import { Alert, AlertTypes } from '~/@types/meta';

const alertHandler = (context: Context) => ({
  /**
   * Show alert in the system
   *
   * @param alertMessage
   * @param alertType
   * @param errorDetails
   * @param dismissible
   * @param displayDurationInMs
   * @param autoDismiss
   */
  show (
    alertMessage: string,
    alertType: AlertTypes = 'success',
    errorDetails: string | null = null,
    dismissible: boolean = true,
    autoDismiss: boolean = true,
    displayDurationInMs: number = 5000,
  ) {
    // Set alert
    // @ts-ignore
    const alerts: Alert[] = Object.assign([], context.store.state.meta.alerts);
    // @ts-ignore
    const lastAlertId: number = context.store.state.meta.alertIdCounter;
    const newAlertId = lastAlertId + 1;

    // Log error to Sentry
    if (alertType === 'error' && errorDetails !== null) {
      const error = `Error msg: ${alertMessage}, Error details: ${errorDetails}`;
      context.$sentry.captureException(new Error(error));
    }

    // Set default error
    if (alertType === 'error' && (!alertMessage || alertMessage.length < 2)) {
      alertMessage = 'Oops! something went wrong. Please try again later.';
    }

    alerts.push({
      id: newAlertId,
      message: alertMessage,
      type: alertType,
      dismissible,
      displayDuration: alertType === 'success' ? 5000 : 10000,
      autoDismiss,
    });
    // @ts-ignore
    context.store.commit('meta/setAlert', alerts);
    // @ts-ignore
    context.store.commit('meta/setAlertIdCounter', +1);

    if (autoDismiss) {
      setTimeout(() => {
        (this as any).dismiss(newAlertId);
      }, displayDurationInMs);
    }

    return newAlertId;
  },

  /**
   * Dismiss the alert by the given id
   *
   * @param alertId
   */
  dismiss (alertId: number) {
    // @ts-ignore
    const alerts: Alert[] = Object.assign([], context.store.state.meta.alerts);
    for (const alert of alerts) {
      if (alert.id === alertId) {
        const index = alerts.indexOf(alert);
        alerts.splice(index, 1);
      }
    }
    // @ts-ignore
    context.store.commit('meta/setAlert', alerts);
  },
});

export default (context: Context, inject: Inject) => {
  const alert: any = alertHandler(context);

  // Inject alert service as a global nuxt module. You can access using $api.alert(params)
  inject('alert', alert);
};
