// eslint-disable-next-line import/named
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { UserResponse, User, StudentUser } from '~/@types/auth';
import { ApiDictionary, FetchingParams, StandardResponse } from '~/@types/meta';

interface StateInterface {
  userResponse: any | null;
  studentResponse: any | null;
  isUsersFetching: boolean;
  fetchingParams: FetchingParams;
  searchQuery: string;
  searchRoleId: number[];
  studentStatuses: ApiDictionary[];
  searchUserIds: number[];
  searchStatus: string[];
}

/**
 * States
 */
export const state = (): StateInterface => ({
  userResponse: null,
  studentResponse: null,
  isUsersFetching: false,
  fetchingParams: {
    perPage: 15,
    page: 1,
    orderBy: [],
    orderDesc: [],
  },
  searchQuery: '',
  searchRoleId: [],
  studentStatuses: [],
  searchUserIds: [],
  searchStatus: [],
});

/**
 * Actions
 */
export const actions: ActionTree<StateInterface, any> = {
  /**
   * Validate user invitation token
   *
   * @param commit
   * @param state
   * @param tokenData
   * @constructor
   */
  VALIDATE_USER_INVITATION_TOKEN (
    { commit, state },
    tokenData: { email: string; token: string },
  ): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api
        .post('/users/validate-invitation', tokenData)
        .then((response) => {
          resolve(response);
        });
    });
  },

  /**
   * Get a list of users
   *
   * @param commit
   * @param state
   * @param filterData
   * @constructor
   */
  GET_USERS ({ commit, state }): any {
    return new Promise((resolve) => {
      // Set user isUserFetching to true before the fetching is start
      commit('SetIsUsersFetching', true);
      const queryParams = {
        ...state.fetchingParams,
        searchQuery: state.searchQuery,
        role_ids: state.searchRoleId,
      };

      // @ts-ignore
      (this as any).$api.get('/users/', queryParams).then((response) => {
        // Display error if not success
        if (response.code !== 200) {
          // @ts-ignore
          (this as any).$alert.show(
            response.data ||
              'Oops! something went wrong while getting the user list.',
            'error',
          );
        }

        commit('SetUserResponse', response.code === 200 ? response.data : null);

        // Set user isUserFetching to false after the fetching is finish
        commit('SetIsUsersFetching', false);

        resolve(response);
      });
    });
  },

  /**
   * Get a list of students
   *
   * @param commit
   * @param state
   * @param filterData
   * @constructor
   */
  GET_STUDENTS ({ commit, state }): any {
    return new Promise((resolve) => {
      // Set user isUserFetching to true before the fetching is start
      commit('SetIsUsersFetching', true);
      const queryParams = {
        ...state.fetchingParams,
        searchQuery: state.searchQuery,
        assignees: state.searchUserIds,
        status: state.searchStatus,
      };
      // @ts-ignore
      (this as any).$api.get('students', queryParams).then((response) => {
        // Display error if not success
        if (response.code !== 200) {
          // @ts-ignore
          (this as any).$alert.show(
            response.data ||
              'Oops! something went wrong while getting the student list.',
            'error',
          );
        }
        // Commit the users
        commit(
          'SetStudentResponse',
          response.code === 200 ? response.data : null,
        );

        // Set user isUserFetching to false after the fetching is finish
        commit('SetIsUsersFetching', false);

        resolve(response);
      });
    });
  },

  /**
   * Get a list of users
   *
   * @param commit
   * @param state
   * @param roleIds
   * @constructor
   */
  GET_ALL_USERS_BY_ROLE ({ commit, state }, roleIds: number[]): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api
        .get('/users/get-by-role', roleIds)
        .then((response: any) => {
          // Display error if not success
          if (response.code !== 200) {
            // @ts-ignore
            (this as any).$alert.show(
              response.data ||
                'Oops! something went wrong while getting the user list.',
              'error',
            );
          }
          resolve(response);
        });
    });
  },

  /**
   * Get a single user
   *
   * @param commit
   * @param state
   * @param userId
   * @param filterData
   * @constructor
   */
  GET_SINGLE_USER ({ commit, state }, userId: number): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api
        .get(`/users/single/${userId}`)
        .then((response: any) => {
          // Display error if not success
          if (response.code !== 200) {
            // @ts-ignore
            (this as any).$alert.show(
              response.data ||
                'Oops! something went wrong while getting the user list.',
              'error',
            );
            resolve(null);
          } else {
            resolve(response.data);
          }
        });
    });
  },

  /**
   * Invite new user
   *
   * @param commit
   * @param state
   * @param userData
   * @constructor
   */
  CREATE_NEW_STUDENT ({ commit, state }, data: any): any {
    return new Promise((resolve) => {
      (this as any).$api
        .post('/users/student', data)
        .then((response: StandardResponse<StudentUser>) => {
          // Display success/error
          if (response.code === 200) {
            // @ts-ignore
            (this as any).$alert.show('User has been invited');
          } else if (response.data && response.data) {
            // @ts-ignore
            (this as any).$alert.show('Oops! something went wrong.', 'error');
          } else {
            // @ts-ignore
            (this as any).$alert.show('Oops! something went wrong.', 'error');
          }
          resolve(response);
        });
    });
  },

  /**
   * Resend invitation
   *
   * @param commit
   * @param state
   * @param userData
   * @constructor
   */
  RESEND_INVITATION ({ commit, state }, data: any): any {
    return new Promise((resolve) => {
      (this as any).$api
        .post('/users/invitation/resend', data)
        .then((response: StandardResponse<StudentUser>) => {
          // Display success/error
          if (response.code === 200) {
            // @ts-ignore
            (this as any).$alert.show('User invitation send.');
          } else if (response.data && response.data) {
            // @ts-ignore
            (this as any).$alert.show('Oops! something went wrong.', 'error');
          } else {
            // @ts-ignore
            (this as any).$alert.show('Oops! something went wrong.', 'error');
          }
          resolve(response);
        });
    });
  },

  /**
   * Invite new user
   *
   * @param commit
   * @param state
   * @param userData
   * @constructor
   */
  CREATE_NEW_USER ({ commit, state }, userData: User): any {
    return new Promise((resolve) => {
      // @ts-ignore
      (this as any).$api.post('/users', userData).then((response: any) => {
        // Display success/error
        if (response.code === 200) {
          // @ts-ignore
          (this as any).$alert.show('User has been invited');
        } else if (response.data && response.data.data) {
          // @ts-ignore
          (this as any).$alert.show(
            response.data.data || 'Oops! something went wrong.',
            'error',
          );
        } else {
          // @ts-ignore
          (this as any).$alert.show(
            response.data || 'Oops! something went wrong.',
            'error',
          );
        }
        resolve(response);
      });
    });
  },
};

/**
 * Mutations
 */
export const mutations: MutationTree<StateInterface> = {
  SetUserResponse (state, userResponse: UserResponse) {
    state.userResponse = userResponse;
  },
  SetStudentResponse (state, studentResponse: UserResponse) {
    state.studentResponse = studentResponse;
  },
  SetIsUsersFetching (state, isUsersFetching: boolean) {
    state.isUsersFetching = isUsersFetching;
  },
  setStudentStatuses (state, statuses: ApiDictionary[]) {
    state.studentStatuses = statuses;
  },
  setFetchParams (state, fetchingParams: FetchingParams) {
    state.fetchingParams = fetchingParams;
  },
  setSearchQuery (state, searchQuery: string) {
    state.searchQuery = searchQuery;
  },
  setSearchRoleId (state, roles: number[]) {
    state.searchRoleId = roles;
  },
  setSearchUserIds (state, user_ids: number[]) {
    state.searchUserIds = user_ids;
  },
  setSearchStatus (state, status: string) {
    state.searchStatus = [status];
  },
};

/**
 * Getters
 */
export const getters: GetterTree<StateInterface, any> = {
  hourChoices () {
    return ['0-2', '3-5', '6-10', '10+'];
  },
  specialExamConditionsOptions () {
    return ['None', 'UCATSA', 'UCATSEN', 'UCATSENSA'];
  },
};
