import { Context } from '@nuxt/types';
import { ApiReturn } from '../api-service';
import { Exam, ExamTemplate } from '~/@types/exam';

export const examFactory = (context: Context) => ({
  /**
   * GET Request
   *
   * @param url
   * @param params
   */
  async getAll (params: any): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.get('/templates?all=true');
  },

  async Paginated (params: any): Promise<ApiReturn> {
    return await context.$api.get('/templates', params);
  },

  async Store (params: any): Promise<ApiReturn> {
    return await context.$api.post('/templates', params);
  },

  async Publish (exam_id: number): Promise<ApiReturn> {
    return await context.$api.put('/templates/publish/' + exam_id);
  },

  async StoreNewExam (params: any): Promise<ApiReturn> {
    return await context.$api.post('/exams', params);
  },

  async attachModulesToExam (params: any): Promise<ApiReturn> {
    return await context.$api.post('/exams/add-questions', params);
  },

  async addOrderToExam (exams: number[]): Promise<ApiReturn> {
    return await context.$api.post('/exams/add-order', {
      exams,
    });
  },
  async addBenchmarkToExam (
    id: number,
    data: { benchmark: number; section_benchmark: number },
  ): Promise<ApiReturn> {
    return await context.$api.put(`/exams/${id}/benchmark`, data);
  },
  async markExamDiagnostic (id: number): Promise<ApiReturn> {
    return await context.$api.post(`/exams/${id}/diagnostic`);
  },
  async removeOrderFromExam (id: number): Promise<ApiReturn> {
    return await context.$api.post('/exams/remove-order', {
      exam_id: id,
    });
  },
  async saveExamOrder (exams: {
    id: number;
    order: number;
  }): Promise<ApiReturn> {
    return await context.$api.post('/exams/update-order', {
      exams,
    });
  },

  updateTemplate (
    template: ExamTemplate,
    publish: boolean = false,
  ): Promise<ApiReturn> {
    if (!template.id) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('No template id');
    }

    const payload = {
      name: template.name,
      intro_text: template.intro_text,
      diagnostic_intro_text: template.diagnostic_intro_text,
      time_limit: template.time_limit,
      diagnostic_time_limit: template.diagnostic_time_limit,
      sections: template.sections,
      publish,
    };
    return context.$api.put(`/templates/${template.id}`, payload);
  },

  QuestionDifficulties (): Promise<number[]> {
    return new Promise((resolve) => {
      const difficulties = [...Array(7).keys()].map(i => i + -3);
      return resolve(difficulties);
    });
  },

  async markSectionTested (exam_id: number, section_id: number) {
    return await context.$api.post(
      `/exams/${exam_id}/section/${section_id}/tested`,
    );
  },

  async publishExam (exam: Exam) {
    return await context.$api.post(`/exams/${exam.id}/publish`);
  },

  async editExam (exam: Exam) {
    return await context.$api.post(`/exams/${exam.id}/clone`);
  },

  async archiveExam (exam: Exam) {
    return await context.$api.post(`/exams/${exam.id}/archive`);
  },
});
