
export default {
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },
  },
};
