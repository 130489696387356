import { Context } from '@nuxt/types';
import { ApiReturn } from '../api-service';

export const systemFactory = (context: Context) => ({
  /**
   * GET Request
   *
   * @param url
   * @param params
   */
  async dictionary (type: string): Promise<ApiReturn> {
    // Make the api call
    return await context.$api.get(`/dictionaries/${type}`);
  },
});
